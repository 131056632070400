body {
  margin: 0;
  padding: 0;
}
#root {
  display: flex;
  background-color: #f0f0f0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

